/* global document */
import React,{ useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import Button from 'components/Atoms/Button';
import { ChevronLeftIcon, CancelMiddleIcon } from '../../Atoms/Icons';
import { useOuterClick, isDOMTypeElement, isElement, Timer } from 'utils';
import { usePrevious } from 'utils';
import { use100vh } from 'react-div-100vh'
import { MoreInfoIcon } from 'components/Atoms/Icons';
import { freezeBodyOnModalOpen } from 'utils';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

export const Modal = ({
    portalNodeId = 'root_modal',
    className,
    title,
    enabled,
    onClose = () => null,
    onBackClick = () => null,
    children,
    fullScreen,
    closeBtn = true,
    notScrollable,
    backBtn = false,
    backBtnText = 'Back',
    ...props
}) => {
    let modal_header = null;
    let modal_footer = null;
    const header = React.Children.map(children, child => child.type.displayName === 'Header' ? child : null);
    const body = React.Children.map(children, child => child.type.displayName === 'Content' ? child : null);
    const footer = React.Children.map(children, child => child.type.displayName === 'Footer' ? child : null);
    const prevEnabled = usePrevious({ enabled })
    const height = use100vh();

    /**
     * Methods
     */
    const handleCloseModal = () => onClose();

    /**
     * Events
     */
    React.useEffect(
        () => freezeBodyOnModalOpen()
        , [enabled, freezeBodyOnModalOpen]
    );

    /**
     * Render
     */
    if(!enabled) return null;
    const portalNode = document.getElementById(portalNodeId);
    if(title){
        modal_header = (
            <div className="n-modal__header">
                {
                    backBtn
                    && (
                        <Button
                            title={backBtnText}
                            icon={ChevronLeftIcon}
                            className="n-modal__back"
                            onClick={onBackClick}
                        />
                    )
                }
                <h4>{title}</h4>
                {
                    closeBtn
                    && (
                        <CancelMiddleIcon
                            className="n-modal__close"
                            onClick={handleCloseModal}
                        />
                    )
                }
            </div>
        );
    } else if (header && !!header.length) {
        modal_header = (
            <div className="n-modal__header">
                {header}
                <CancelMiddleIcon
                    className="n-modal__close"
                    onClick={handleCloseModal}
                />
            </div>
        );
    }

    if (footer && footer.length){
        modal_footer = (
            <div className="n-modal__footer center-save">
                {footer}
            </div>
        );
    } else if (fullScreen) {
        modal_footer = (
            <div className="n-modal__footer">
                <Button
                    title={backBtnText}
                    icon={ChevronLeftIcon}
                    className="n-modal__back"
                    onClick={handleCloseModal}
                />
            </div>
        );
    } else {
        modal_footer = (
            <div className="n-modal__footer n-modal__footer--empty" />
        )
    }

    const modal = (
        <div
            className={(clsx(
                'n-modal',
                className,
                {
                    'n-modal--active': enabled,
                    'n-modal--has-header': !!modal_header,
                    'n-modal--fullscreen': fullScreen,
                    'n-modal--not-scrollable': notScrollable
                }
            ))}
            style={{
                height: fullScreen ? (height || '100vh') : null
            }}
            {...props}
            >
            <div className="n-modal__cover" onClick={handleCloseModal} />
            <div
                className="n-modal__dialog"
                style={{
                    height: fullScreen ? (height || '100vh') : null
                }}
            >
                {modal_header}
                <div className="n-modal__content">
                    {(body && !!body.length) ? body : (children || null)}
                </div>
                {modal_footer}
            </div>
        </div>
    );

    return portalNodeId ? ReactDOM.createPortal(modal, portalNode) : modal;
}

const Header = ({ children }) => children;
Header.displayName = 'Header';
Modal.Header = Header;

const Content = ({ children }) => <>{children}</>;
Content.displayName = 'Content';
Modal.Content = Content;

const Footer = ({ children }) => children;
Footer.displayName = 'Footer';
Modal.Footer = Footer;

export const Tooltip = ({
    children,
    className = '',
    enabled = false,
    content,
    dark,
    trigger = 'hover', // hover, click
    position = 'top', // top, right, left
    modalMode,
    modal = {},
    ...props
}) => {
    const [active, setActive] = useState(false);
    const innerRef = useRef();
    useOuterClick(innerRef, () => {
        if(trigger === 'click') {
            setActive(false);
        }
    });
    const modal_info = {
        title: null,
        className,
        backBtnText: 'Back',
        fullScreen: false,
        ...modal,
    };

    return (
        <>
            <span
                className={clsx(
                    'n-tooltip',
                    className,
                    {
                        [`n-tooltip--${position}`]: !!position,
                        'n-tooltip--dark': dark,
                        'n-tooltip--enable': enabled,
                        'n-tooltip--hover': trigger === 'hover' && !modalMode,
                        'n-tooltip--click': trigger === 'click' || modalMode,
                        'n-tooltip--active': (active && !modalMode)
                    },
                )}
                {...props}
                ref={innerRef}
            >
                <span onClick={() => setActive(prevState => (!prevState))}>
                    {children}
                </span>
                {
                    !modalMode
                    && (
                        (isDOMTypeElement(content) || isElement(content))
                        ? (
                            <span className="n-tooltip__inner">{content}</span>
                        ) : (
                            <span
                                className="n-tooltip__inner"
                                dangerouslySetInnerHTML={{__html: content }}
                            />
                        )
                    )
                }
            </span>
            <Modal
                enabled={modalMode && enabled && active}
                onClose={() => setActive(false)}
                className={clsx('n-tooltip__modal', modal_info.className)}
                fullScreen={modal_info.fullScreen}
                title={modal_info.title}
                backBtnText={modal_info.backBtnText}
                notScrollable
            >
                <Modal.Content>
                    {
                        (isDOMTypeElement(content) || isElement(content))
                        ? content
                        : <div dangerouslySetInnerHTML={{__html: content }} />
                    }
                </Modal.Content>
            </Modal>
        </>
    );
}


export const MobileAction = ({
    portalNodeId = 'root_modal',
    className,
    title,
    enabled,
    onClose = () => null,
    children,
    notScrollable,
    ...props
}) => {
    const prevEnabled = usePrevious({ enabled })
    /**
     * Events
     */
    useEffect(
        () => freezeBodyOnModalOpen()
        , [enabled, prevEnabled]
    );

    /**
     * Render
     */
    if(!enabled) return null;
    const portalNode = document.getElementById(portalNodeId);

    const modal = (
        <div
            className={(clsx(
                'n-mobile-action',
                className,
                {
                    'n-mobile-action--active': enabled,
                    'n-mobile-action--not-scrollable': notScrollable
                }
            ))}
            {...props}
            >
            <div className="n-mobile-action__dialog">
                <div className="n-mobile-action__header">
                    {
                        title
                        && <h4>{title}</h4>
                    }
                    <CancelMiddleIcon
                        className="n-mobile-action__close"
                        onClick={() => onClose()}
                    />
                </div>
                <div className="n-mobile-action__content">
                    {children}
                </div>
            </div>
        </div>
    );

    return portalNodeId ? ReactDOM.createPortal(modal, portalNode) : modal;
}


export const Toastr = ({
    enabled,
    children,
    duration = 3000,
    onClose = () => null
}) => {
    const timer_ref = useRef();
    const [active, setActive] = useState(false);

    /**
     * Methods
     */
    const handleClose = useCallback(() => {
        setActive(false);
        onClose();
    });
    const handlePause = useCallback(
        () => {
            if(timer_ref?.current)
                timer_ref.current.pause();
        }, [timer_ref]
    );
    const handleResume = useCallback(
        () => {
            if(timer_ref?.current)
                timer_ref.current.resume();
        }, [timer_ref]
    );

    /**
     * Events
     */
    useEffect(() => {
        if (enabled) {
            setTimeout(() => setActive(true), 400);
            timer_ref.current = new Timer(() => handleClose(), duration);
        }
    }, [duration, enabled, handleClose]);
    useEffect(() => {
        if (!enabled && active) {
            handleClose();
        }
    }, [enabled, active, handleClose]);

    return (
        <div
            className={clsx('n-toastr', {
                'n-toastr--active': active
            })}
            onMouseEnter={handlePause}
            onMouseLeave={handleResume}
        >
            <div className="n-toastr__content">
                {children}
            </div>
            <CancelMiddleIcon
                className="n-toastr__close"
                onClick={handleClose}
            />
        </div>
    );
}

export const InfoTooltip = ({
    content,
    modal = {},
}) => {
    const { isDesktop } = useDeviceDetect();
    const modal_info = {
        title: 'Title',
        fullScreen: true,
        backBtnText: "Back",
        ...modal
    };

    return (
        <Tooltip
            enabled={!!content}
            dark
            content={content}
            modalMode={!isDesktop}
            modal={modal_info}
        >
            <MoreInfoIcon />
        </Tooltip>
    )
}