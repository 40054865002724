import moment from "moment";
import React from "react";

export * from "./CustomHooks";
export * from "./Constant";

export { default as GuestTestData } from "./TestData/checkout/guest_detail_data";
export { default as SideWidgetTestData } from "./TestData/checkout/side_widget_data.json";

export const isClassComponent = (component) =>
  typeof component === "function" && !!component.prototype.isReactComponent;

export const isFunctionComponent = (component) =>
  typeof component === "function" &&
  String(component).includes("return React.createElement");

export const isReactComponent = (component) =>
  isClassComponent(component) || isFunctionComponent(component);

export const isElement = (element) => React.isValidElement(element);

export const isDOMTypeElement = (element) =>
  isElement(element) && typeof element.type === "string";

export const isCompositeTypeElement = (element) =>
  isElement(element) && typeof element.type === "function";

export const Timer = function (callback, delay) {
  let timerId;
  let start;
  let remaining = delay;

  this.pause = function () {
    window.clearTimeout(timerId);
    remaining -= Date.now() - start;
  };

  this.resume = function () {
    start = Date.now();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, remaining);
  };

  this.resume();
};

export const formatMoment = (date, format = "DD MMM YYYY") => {
  if (!date) return null;
  if (date && date._isAMomentObject) return date.format(format);
  return moment(date).format(format);
};

export const getLDPQueryParamsPreset = ({
  checkin,
  checkout,
  adults,
  children,
  infants,
  rooms,
}) => {
  const intValidator = (currentValue, defaultValue) =>
    Number.parseInt(currentValue) > 0 ? currentValue : defaultValue;

  const validatedQueryParams = Object.entries({
    adults: [adults, 2],
    children: [children, 0],
    infants: [infants, 0],
    rooms: [rooms, 1],
  }).reduce(
    (acc, [key, [currentValue, defaultValue, validator = intValidator]]) => ({
      ...acc,
      [key]: validator(currentValue, defaultValue),
    }),
    {}
  );

  if (validatedQueryParams.rooms > validatedQueryParams.adults) {
    validatedQueryParams.adults = validatedQueryParams.rooms;
  }

  return {
    ...validatedQueryParams,
    checkin: checkin || null,
    checkout: checkout || null,
  };
};

export const getProductIdFromSlug = (slug) => slug.split("-").pop();
