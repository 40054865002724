import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import clsx from 'clsx';
import { FormInlineInsert } from 'components/Molecules/Form';
import { RadioCircleIcon , RadioCircleSelectedIcon } from 'components/Atoms/Icons';

const Radiobox = ({
  label,
  subtitle,
  suffix,
  isErrorComponentAllowed,
  ...props
}) => {
  const [field, meta] = useField(props);
  /**
   * Computed
   */
  const is_checked = useMemo(
    () => field.value == props.value
    ,[props, field]
  );

  return (
    <div className={clsx('n-radiobox')} >
      <label>
        {
            is_checked
            ? <RadioCircleSelectedIcon className="icon-radiobox" />
            : <RadioCircleIcon className="icon-radiobox" />
        }
        <Field type="radio" {...field} {...props} />
        <span className="text">
          <span>{label}</span>
          {
            subtitle
            && (<span>{subtitle}</span>)
          }
        </span>
      </label>
      {suffix || ''}
      {
        (isErrorComponentAllowed && meta.touched && meta.error)
        && (
          <div className="error">{meta.error}</div>
        )
      }
    </div>
  );
};

Radiobox.propTypes = {
  label: PropTypes.string,
};

const RadioboxList = ({
  className,
  title,
  name,
  items,
  onItemClick = () => null,
  enableAdding = false,
  addingBtnTitle = 'add new',
  onAdd = () => null,
  isErrorComponentAllowed = true
}) => (
  <div
    className={clsx('n-radiobox-list', className, {})}
  >
    {
      !!title
      && (
        <strong id="radiobox-group">{title}</strong>
      )
    }
    <ul role="group" aria-labelledby="radiobox-group">
      {items.map((item, indx) => (
        <li
          key={indx}
          onClick={() => onItemClick(item)}
          className={clsx(item?.disabled && 'disabled')}
        >
          <Radiobox
            isErrorComponentAllowed={isErrorComponentAllowed}
            name={name}
            {...item}
          />
        </li>
      ))}
    </ul>
    <FormInlineInsert
      enabled={enableAdding}
      input={{
        placeholder: 'Enter the name of service or facility'
      }}
      button={{
        title: addingBtnTitle
      }}
      onSubmit={onAdd}
    />
  </div>
)

export {
    Radiobox,
    RadioboxList,
}