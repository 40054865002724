import React from "react";
import clsx from "clsx";
import throttle from "lodash.throttle";

export function useOuterClick(ref, handler) {
    React.useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

export function useViewportSpecificCssClasses({ isMobile, isTablet, isDesktop }) {
    return React.useCallback(base => clsx({
        [base]: true,
        [`${base}--mobile`]: isMobile,
        [`${base}--tablet`]: isTablet,
        [`${base}--desktop`]: isDesktop
    }), [isMobile, isTablet, isDesktop]);
}

export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });

    React.useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export const useNumberInputValidation = () => {
    return React.useCallback((value, { min = 0, max = Number.MAX_SAFE_INTEGER } = {}) => {
        if (value.length === 0) {
            return '';
        }

        const priceValue = Number.parseFloat(value);
        if (!Number.isNaN(priceValue) && (priceValue >= min) && (priceValue <= max)) {
            return priceValue;
        }

        return false;
    }, []);
};

let freezeBodyOnModalOpenTimer;
export const freezeBodyOnModalOpen = () => {
    clearTimeout(freezeBodyOnModalOpenTimer);
    freezeBodyOnModalOpenTimer = setTimeout(
        () => {
            const body = document.getElementsByTagName('body');
            const opened_modals = document.querySelectorAll('#root_modal > .n-modal');
            if(body && body.length) {
                if(opened_modals.length && !body[0].hasAttribute('freeze')) {
                    body[0].setAttribute('freeze', true);
                } else if (!opened_modals.length && body[0].hasAttribute('freeze')) {
                    body[0].removeAttribute('freeze');
                }
                if(opened_modals.length) {
                    body[0].classList.add('body--freeze');
                } else {
                    body[0].classList.remove('body--freeze');
                }
            }
        }, 100
    )
}


export const useThrottle = (cb, delay) => {
  const options = { leading: true, trailing: false }; // add custom lodash options
  const cbRef = React.useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  React.useEffect(() => { cbRef.current = cb; });
  return React.useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}