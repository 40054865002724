import { useState } from 'react';
import clsx from 'clsx';
import { ChevronRightIcon } from '../../Atoms/Icons';
import Button from 'components/Atoms/Button';
import { isElement } from '../../../utils';
import { Modal } from '../Modal';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

const ListItem = ({item}) => {
    if (typeof item === 'string' || isElement(item)) return item;
    const { icon, url, title } = item;
    const Icon = icon;
    return (
        <>
            {
                icon
                && <Icon />
            }
            {
                (item && url)
                ? <a href={url}>{title}</a>
                : title
            }
        </>
    )
}

export const List = ({
    enabled = true,
    className,
    twoColumn,
    threeColumn,
    fourColumn,
    leftIcon,
    title,
    items,
    onItemClick = () => null
}) => {
    if(!enabled) return null;
    return (
        <div className={
            clsx(
                'list',
                className,
                {
                    'list--left-icon': leftIcon,
                    'list--two-col': twoColumn,
                    'list--three-col': threeColumn,
                    'list--four-col': fourColumn
                }
            )
        }>
            {
                !!title
                && (
                    <strong>
                        {title}
                    </strong>
                )
            }
            <ul>
                {(items || []).map((item, indx) => (
                    <li key={indx} onClick={() => onItemClick(item)}>
                        <ListItem 
                            key={indx}
                            item={item}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export const ListDetailModal = ({
    enabled = true,
    enabledModal = true,
    className,
    title,
    twoColumn,
    items = [],
    limit = -1,
    onClickMore = () => null,
    btnFormat = 'Show all {num} items',
    icon = ChevronRightIcon,
    modal = {
        title: 'Detail Modal',
        backBtnText: 'Back to listing details'
    },
    modalContent = null
}) => {
    if(!enabled) return null;
    const { isDesktop } = useDeviceDetect();
    const [modal_enabled, setModalEnabled] = useState(false);

    /**
     * Method
     */
    const handleClickMore = () => {
        onClickMore();
        if(enabledModal)
            setModalEnabled(true);
    }

    return (
        <div className={clsx('list-detail-modal', className)}>
            <List
                title={title}
                twoColumn={twoColumn}
                items={(limit !== -1 ? [...items].slice(0, limit) : items)}
            />
            {
                !!(items.length > limit && limit !== -1)
                && (
                    <Button
                        title={btnFormat.replace('{num}', items.length)}
                        icon={icon}
                        align="space-between"
                        noPadding
                        onClick={handleClickMore}
                    />
                )
            }
            {
                enabledModal
                && (
                    <Modal
                        enabled={modal_enabled}
                        onClose={() => setModalEnabled(false)}
                        fullScreen={!isDesktop}
                        title={modal.title}
                        closeBtn={isDesktop}
                        backBtnText={modal.backBtnText}
                        className="modal-list-detail-modal"
                    >
                        <Modal.Content>
                            {
                                modalContent || (
                                    <List
                                        twoColumn={isDesktop}
                                        items={items}
                                    />
                                )
                            }
                        </Modal.Content>
                    </Modal>    
                )
            }
        </div>
    )
}



export const ExpandableList = ({
    className,
    title,
    twoColumn,
    items = [],
    limit = -1,
    onClickMore = () => null,
    btnFormat = 'Show all {num} items',
    icon = ChevronRightIcon
}) => (
    <div
        className={clsx('expandable-list', className, {
            'expandable-list--two-col': twoColumn
        })}
    >
        <strong className="expandable-list__title">{title}</strong>
        <ul className="expandable-list__content">
            {
                (limit !== -1 ? [...items].slice(0, limit) : items).map((item, indx) => (
                    <li key={indx}>
                        <ListItem 
                            key={indx}
                            item={item}
                        />
                    </li>
                ))
            }
        </ul>
        {
            (items.length > limit && limit !== -1)
            ? <div className="expandable-list__footer">
                <Button
                    title={btnFormat.replace('{num}', items.length)}
                    icon={icon}
                    align="space-between"
                    noPadding
                    onClick={onClickMore}
                />
            </div>
            : null
        }
    </div>
);


const defaultDescriptionItemMapper = (item) => (
    <>
        <h5>{item.title}</h5>
        <div dangerouslySetInnerHTML={{ __html: (item.description || '') }}></div>
    </>
);
export const DescriptionList = ({ className, twoColumn, items, itemMapper = defaultDescriptionItemMapper }) => (
    <ul
        className={clsx('description-list', className, {
            'description-list--two-col':twoColumn
        })}
    >
        {(items || []).map((item, indx) => <li key={indx}>{itemMapper(item)}</li>)}
    </ul>
);
