import clsx from 'clsx';
import React, { memo, useCallback } from 'react';

const Picture = ({
    className,
    classes,
    src,
    alt,
    loading,
    sources,
    onLoad,
    onError,
    fallbackSrc
}) => {
    const onErrorHandler = useCallback(e => {
        const { target } = e;
        target.src = fallbackSrc;
        target.classList.add(classes.imageLoadedError ?? 'cn__image--loaded-error');
        onError?.(e);
    }, [classes.imageLoadedError]);


    const onLoadHandler = useCallback(e => {
        const { target } = e;
        target.classList.add(classes.imageLoadedSuccess ?? 'cn__image--loaded-success');
        onLoad?.(e);
    }, [classes.imageLoadedSuccess]);

    return (
        <picture
            className={clsx(
                'cn__image-picture',
                className,
                classes.root
            )}
        >
            {sources.map(({ media, srcset, type }, index) => (
                <source
                    key={String(index)}
                    media={media}
                    srcSet={srcset}
                    type={type}
                />
            ))}
            <img
                src={src || ''}
                alt={alt}
                loading={loading}
                className={clsx('cn__image-default', classes.image)}
                onError={onErrorHandler}
                onLoad={onLoadHandler}
            />
        </picture>
    )
};

Picture.defaultProps = {
    sources: [],
    fallbackSrc: '/static/staticpages/newsroom/noimage.jpg',
    loading: 'lazy',
    classes: {
        root: undefined,
        image: undefined,
        imageLoadedError: undefined,
        imageLoadedSuccess: undefined
    }
};

export default memo(Picture);
