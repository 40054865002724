import { useMemo } from "react";
import clsx from "clsx";
import pluralize from 'pluralize';
import {
    PersonIcon,
    FloorAltIcon,
    BathOutlinedIcon,
    ChevronRightIcon
} from 'components/Atoms/Icons';
import {
    List,
    MultiImageModal,
    Loader
} from "components/Molecules";

export const Room = ({
    loading = false,
    item_info = {},
    bigImage,
    customImageDetail = false,
    onImageClick = () => null,
    onTitleClick = () => null,
    children
}) => {
    const item_options = useMemo(
        () => {
            const options = [];
            if(item_info.guests){
                options.push({
                    title: `${item_info.guests} ${pluralize('guest', item_info.guests)}`,
                    icon: PersonIcon
                })
            }

            const bath = (item_info.amenities || []).find(amenity => amenity.toLowerCase().indexOf('bath') !== -1)
            if(bath){
                options.push({
                    title: bath,
                    icon: BathOutlinedIcon
                })
            }

            if(item_info.unit_size){
                options.push({
                    title: `${item_info.unit_size}m²`,
                    icon: FloorAltIcon
                })
            }

            return options;
        }
        , [item_info]
    );
    const images = useMemo(
        () => (item_info?.images || []).map(img => ({ url: img, alt: (item_info.unit_name || 'room alt') }))
        , [item_info]
    );
    const options_length = useMemo(() => item_options.length , [item_options]);

    return (
        <article
            className={clsx('room', {
                'room--big-img': bigImage,
                'room--is-multi-img': images.length > 1
            })}
        >
            <Loader enabled={loading}>
                <Loader.Loading>
                    <Loader.Icon height={40} width={70} />
                    <Loader.Icon height={40} width={70} />
                    <div className="room__img">
                        {
                            bigImage
                            ? <Loader.Icon height={210} width='100%' />
                            : <Loader.Icon height={100} width='100%' />
                        }
                    </div>
                    <h4><Loader.Icon height={25} width={170} /></h4>
                    <List
                        leftIcon
                        items={[
                            <Loader.Icon height={25} width={130} />,
                            <Loader.Icon height={25} width={130} />,
                            <Loader.Icon height={25} width={130} />,
                            <Loader.Icon height={25} width={130} />
                        ]}
                        className="list--item-4"
                    />
                    {
                        children
                        && (
                            <div className="details">
                                {children}
                            </div>
                        )
                    }
                </Loader.Loading>
                <Loader.Resolved>
                    <div className="room__img">
                        <MultiImageModal
                            items={images}
                            limit={1}
                            withCover
                            enabledModal={!customImageDetail}
                            onClick={onImageClick}
                            modal={{
                                title: `${item_info.unit_name} photos`
                            }}
                        />
                    </div>
                    <h4 onClick={onTitleClick}>{item_info.unit_name} <ChevronRightIcon /></h4>
                    {
                        (!!options_length)
                        && <List
                            leftIcon
                            items={item_options}
                            className={`list--item-${options_length}`}
                        />
                    }
                    {
                        children
                        && (
                            <div className="details">
                                {children}
                            </div>
                        )
                    }
                </Loader.Resolved>
            </Loader>
        </article>
    )
}
