import React from 'react';
import clsx from 'clsx';

const Card = ({ enabled = true, className, noPadding, radius, title, children, ...props }) => {
    if(!enabled) return null;
    
    let card_header = null;
    const header = React.Children.map(children, child => child?.type.displayName === 'Header' ? child : null);
    const body = React.Children.map(children, child => child?.type.displayName === 'Content' ? child : null);
    const footer = React.Children.map(children, child => child?.type.displayName === 'Footer' ? child : null);

    if(title) {
        card_header = (<h2 className="card__title">{title}</h2>);
    } else if (header && !!header.length) {
        card_header = (<div className="card__header">{header}</div>);
    }

    return (
        <div
            className={
                clsx(
                    'card',
                    className,
                    {
                        'card--no-padding': noPadding,
                        'card--radius': radius
                    }
                )
            }
            {...props}
        >
            {card_header}
            <div className="card__content">
                {(body && !!body.length) ? body : (children || null)}
            </div>
            {
                (footer && !!footer.length) 
                && <div className="card__footer">
                    {footer}
                </div>
            }
        </div>
    );
}

const Header = ({ children }) => children;
Header.displayName = 'Header';
Card.Header = Header;

const Content = ({ children }) => children;
Content.displayName = 'Content';
Card.Content = Content;

const Footer = ({ children }) => children;
Footer.displayName = 'Footer';
Card.Footer = Footer;

export default Card;