import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Loader = ({
    enabled,
    children,
}) => {
    const loading = React.Children.map(children, child => child.type.displayName === 'Loading' ? child : null);
    const resolved = React.Children.map(children, child => child.type.displayName === 'Resolved' ? child : null);

    return (enabled ? loading : resolved);
}

const Resolved = ({ children }) => children;
Resolved.displayName = 'Resolved';
Loader.Resolved = Resolved;

const Loading = ({ children }) => children;
Loading.displayName = 'Loading';
Loader.Loading = Loading;

const Icon = ({...props}) => <Skeleton {...props} />;
Icon.displayName = 'Icon';
Loader.Icon = Icon;

export {
    Loader
};
