import React, { useEffect } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import { usePrevious } from 'utils';

export const TextField = ({
  className,
  field = {},
  meta = {},
  prefix,
  suffix,
  labelOnFill,
  label,
  innerRef,
  children,
  isErrorComponentAllowed = true,
  ...props
}) => {
  const [input_focused, setInputFocus] = React.useState(false);

  return (
    <div
      className={clsx(
        className,
        'n-input-text',
        {
          'n-input-text--fill': !!field.value,
          'n-input-text--label-on-fill': labelOnFill,
          'n-input-text--prefix': prefix,
          'n-input-text--suffix': suffix,
          'n-input-text--focus': input_focused
        }
      )}
    >
      {(label && !labelOnFill)
        && <label htmlFor={props.id || props.name} className="n-input-text__label">{label}</label>}
      {prefix && <span className="n-input-text_prefix">{prefix}</span>}
      {suffix && <span className="n-input-text_suffix">{suffix}</span>}
      <input
        ref={innerRef}
        {...field}
        onFocus={() => setInputFocus(true)}
        // adding this hack due to dumbass Shayan hijacked formik's onblur
        onBlur={(evt) => {
          setInputFocus(false);
          if (field.hasOwnProperty('onBlur')) {
            field.onBlur(evt);
          }
        }}
        {...props}
      />
      {(label && labelOnFill)
        && <label htmlFor={props.id || props.name} className="n-input-text__label">{label}</label>}
      {(isErrorComponentAllowed && meta.touched && meta.error)
        && (
          <div className="error">{meta.error}</div>
        )}
      {children}
    </div>
  );

}

export default ({
  label,
  labelOnFill = true,
  items,
  onSelect,
  onValueChange = () => null,
  selectedValue,
  ...props
}) => {
  const [field, meta] = useField(props);
  const prev_value = usePrevious(field.value);

  /**
   * Method
   */
  useEffect(() => {
    if (field.value !== prev_value && typeof onValueChange === 'function') {
      onValueChange(field.value);
    }
  }, [field, prev_value, onValueChange]);

  return (
    <TextField
      label={label}
      labelOnFill={labelOnFill}
      meta={meta}
      field={field}
      {...props}
    >
      {Array.isArray(items) && (
        <div className="n-dropdown-overlay">
          <ul>
            {items.map(item => (
              <li
                className={clsx({
                  'selected': selectedValue === item.key
                })}
                onClick={() => onSelect(item)}
              >
                {item.value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </TextField>
  );
};