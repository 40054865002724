export default function PrevArrowIcon(props) {
  const { className, style, onClick } = props;
  return (
    <strong className={className}
      onClick={onClick}
    >
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.60864 1.73926L1.56516 6.00013L4.60864 10.261" stroke="#797882" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </strong>
  );
}