import { useEffect, useMemo, useRef, useState } from "react";
import clsx from 'clsx';
import { ChevronDownIcon } from "components/Atoms/Icons";
import { useFormikContext } from "formik";
import { RadioboxList } from "../Radio";
import { usePrevious } from "utils";
import { useOuterClick } from "utils";

const getDescendantProp = (obj, desc) => {
    if(!obj) return false;
    const arr = desc.split(".");
    // what kind of dumb shithead uses this kind of syntax
    while(arr.length && (obj = obj[arr.shift()]));
    // by the way you didn't account for [] brackets within formik names you asshole
    return obj;
}

const SelectPicker = ({
    className,
    name,
    label,
    items = [],
    icon,
    openUp,
    labelOnFill,
    maxItemVisible = 5,
    placeholder = "select an option",
    initialValue,
    onValueChange = () => null,
    enableAdding,
    addingBtnTitle,
    onAdd,
    isErrorComponentAllowed,
    ...props
}) => {
    const _formik = useFormikContext();
    const innerRef = useRef();
    const [active, setActive] = useState(false);
    const Icon = icon;

    /**
     * Computed
     */
    const input_text = useMemo(
        () => items.find(item => item.value == getDescendantProp(_formik?.values, name))?.label || placeholder
        , [placeholder, _formik, name, items]
    );
    const is_filled = useMemo(
        () => (input_text !== placeholder.trim())
        ,[input_text, placeholder]
    );
    const prev_value = usePrevious(input_text);

    /**
     * Methods
     */
    const handleClose = () => setActive(false);
    useEffect(() => {
        const item = items.find(item => item.label == prev_value);
        if(item && typeof onValueChange === 'function' && item.value !== initialValue) {
            onValueChange(item.value);
        }
    }, [onValueChange, items, prev_value]);

    /**
     * Events
     */
    useOuterClick(innerRef, (e) => {
        if(active) {
            handleClose();    
        } 
    });
    
    return (
        <div
            ref={innerRef}
            className={clsx('n-select-picker', className, {
                'open-up': openUp,
                'active': active,
                'filled': is_filled,
                'label-on-fill': labelOnFill
            })}
        >
            {
                (label && !labelOnFill)
                && <label htmlFor={props.id || props.name} className="n-select-picker__label">{label}</label>
            }
            <div
                className="input-value"
                onClick={() => setActive(prevStat => (!prevStat))}
            >
                <span className="text-group">
                    {
                        icon
                        && <Icon className="left-icon" />
                    }
                    <span className="text">{input_text}</span>
                </span>
                <ChevronDownIcon />
            </div>
            {
                (label && labelOnFill)
                && <label htmlFor={props.id || props.name} className="n-select-picker__label">{label}</label>
            }
            <div
                className="input-dropdown"
                style={{
                    maxHeight: (maxItemVisible * 40)
                }}
            >
                <RadioboxList
                    name={name}
                    items={items}
                    onItemClick={() => setActive(false)}
                    enableAdding={enableAdding}
                    addingBtnTitle={addingBtnTitle}
                    onAdd={onAdd}
                    isErrorComponentAllowed={isErrorComponentAllowed}
                />
            </div>
        </div>
    )
};

export default SelectPicker;