import React from 'react';
import { Formik, Form } from 'formik';

const NForm = ({
  enabled = true,
  children,
  className,
  onChange = () => null,
  ...props
}) => {

  if(!enabled) return null;

  const [data, setData] = React.useState();
  const cache_data = React.useMemo(() => data, [data]);
  React.useEffect(() => {
    if(typeof onChange === 'function') {
      onChange(cache_data);
    }
  }, [onChange, cache_data]);

  return (
    <>
        <Formik
          {...props}
        >
          {({ values }) => {
            setData(values); // TODO: it is wrong it could crash in future :/
            return (
              <Form className={className}>
                {children}
              </Form>
            )
          }}
        </Formik>
    </>
  );
}

export default NForm;