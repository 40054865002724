export default function NextArrowIcon(props) {
  const { className, style, onClick } = props;
  return (
    <strong className={className}
      onClick={onClick}
    >
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.39136 10.2607L5.43484 5.99987L2.39136 1.739" stroke="#797882" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </strong>
  );
}