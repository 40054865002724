import { range } from "@/src/utils/globalFunc";
import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { filterTypes } from "@/containers/searchResults/enum";
import clsx from "clsx";

function ChildAgeSelector({
	childArray,
	updatePrimaryChildArray,
	numberOfChildren,
}) {
	const [updatedChildArray, setUpdatedChildArray] = useState(childArray);
	const childrenAges = useSelector(
		(state) => state.searchResults[filterTypes.childrenAges]
	) ?? [];

	useEffect(() => {
		const updatedAges = childArray.map((child, index) => {
			if (!!child.age) return child;
			return {
				...child,
				// This code written by Talha, don't judge line below I've added just question mark
				age: childrenAges?.[index] === undefined ? "2" : childrenAges[index],
			};
		});
		setUpdatedChildArray(updatedAges);
		updatePrimaryChildArray(updatedAges);
	}, []);

	useEffect(() => {
		if(childArray !== updatedChildArray){
			if (numberOfChildren > updatedChildArray.length) {
				const additionalChildren = numberOfChildren - updatedChildArray.length;
				let incrementedArray = Array.from(updatedChildArray);

				Array.from({ length: additionalChildren }, () => {
					incrementedArray.push({ guestAgeType: "CHILD", age: "2" });
				});
				setUpdatedChildArray(incrementedArray);
				updatePrimaryChildArray(incrementedArray);
			} else if (numberOfChildren < updatedChildArray.length) {
				const decrementedArray = Array.from(updatedChildArray);

				decrementedArray.pop();
				setUpdatedChildArray(decrementedArray);
				updatePrimaryChildArray(decrementedArray);
			}
		}
	}, [numberOfChildren]);

	const onChildAgeChange = (event) => {
		const { value, id } = event.target;

		const updatedAges = updatedChildArray.map((child, index) => {
			if (String(index) === id) {
				return { guestAgeType: "CHILD", age: value };
			} else return child;
		});

		setUpdatedChildArray(updatedAges);
		updatePrimaryChildArray(updatedAges);
	};

	return (
		<div
			className={clsx("cn__childAgeSelector", {
				"cn__childAgeSelector-grid": numberOfChildren >= 6,
			})}
		>
			{!!updatedChildArray.length &&
				updatedChildArray?.map((child, index) => {
					const { age } = child;
					return (
						<div className={"cn__childAgeSelector_wrapper"}>
							<span className="cn__childAgeSelector_typography">
								Child {index + 1} Age
							</span>
							<div className="cn__childAgeSelector_select_wrapper">
								<select
									name="age"
									id={index}
									onChange={onChildAgeChange}
									className={clsx("cn__childAgeSelector_select")}
								>
									{range(2, 17).map((ageOption) => (
										<option
											value={ageOption}
											className={clsx("cn__childAgeSelector_select_option")}
											selected={+age === ageOption}
										>
											{ageOption}
										</option>
									))}
								</select>
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default memo(ChildAgeSelector);
