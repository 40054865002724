import React, { memo } from "react";

const ScenicView = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20
                17 20H7C5.34315 20 4 18.6569 4 17V7ZM17 9C17 10.1046 16.1046 11 15 11C13.8954 11 13
                10.1046 13 9C13 7.89543 13.8954 7 15 7C16.1046 7 17 7.89543 17 9ZM6.35598 15.3623L9.14371
                11.9953C9.56086 11.4914 10.3414 11.5171 10.7245 12.0473L12.9205 15.0864C13.2716 15.5723
                13.9685 15.6422 14.4091 15.2356L15.489 14.2393C15.9199 13.8418 16.5989 13.8985 16.9578
                14.3621L17.7519 15.3878C18.2606 16.045 17.7923 17 16.9611 17H7.12623C6.27988 17 5.81623
                16.0142 6.35598 15.3623Z"
            fill="#64748B"
        />
    </svg>
)

export default memo(ScenicView);
