import React from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

const Button = ({
    formBtn = false,
    type,
    title,
    disabled,
    className,
    icon,
    align,
    color,
    leftIcon,
    noPadding,
    fullWidth,
    loading = false,
    onClick = () => null,
    ...props
}) => {
    const Icon = icon;
    const form_info = !!formBtn && useFormikContext();

    const is_disabled = React.useMemo(
        () => {
            if(typeof disabled !== 'undefined') return disabled;
            if(!!formBtn && form_info) return !form_info.isValid;
            return false;
        }, [formBtn, disabled, form_info]
    );
    const btn_color = React.useMemo(
        () => {
            if(typeof color !== 'undefined') return color;
            if(!!formBtn && form_info) {
                return form_info.isValid
                    ? formBtn.valid
                    : (formBtn.normal || 'black');
            }
        }, [color, formBtn, form_info]
    );

    const handleClick = () => {
        if(!loading)
            onClick();
    }
    return (
        <button
            type={type || 'button'}
            className={clsx('n-btn', className, {
                'n-btn--disabled': is_disabled,
                [`n-btn--${align}`]: align,
                [`n-btn--color-${btn_color}`]: btn_color,
                'n-btn--left-icon': leftIcon,
                'n-btn--nopadding': noPadding,
                'n-btn--full-width': fullWidth
            })}
            onClick={handleClick}
            disabled={is_disabled}
            {...props}
        >
            {
                loading
                ? (
                    <img src='/static/img/loader.gif' alt="loading" className="n-btn--loading" />
                ) : (
                    <>
                        {title}
                        {icon && <Icon />}
                    </>
                )
            }
        </button>
    )
};

export default Button;