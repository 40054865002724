import useDateFormatter from "@/containers/homePage/Omnisearch/useDateFormatter";
import moment from "moment";
import { useContext, useEffect, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import { AvailabilityContext } from "./Availability";

export function useMultiUnitAvailability() {
    return useContext(AvailabilityContext);
};

export function useSingleUnitAvailability(filters, setFilters) {
    const store = useStore();

    const isChargeLoading = useSelector(state => state.product.charge.is_loading);
    const isChargeOk = useSelector(state => state.product.charge.is_ok);

    const isAvailabilityOk = useSelector(state => state.product.availablity.is_ok);
    const availabilityData = useSelector(state => state.product.availablity.data);

    const filtersRef = useRef(filters);
    const setFiltersRef = useRef(setFilters);

    const format = useDateFormatter();

    const getDate = checkin =>
        format(moment(checkin))
            .split('-');

    const getNextAvailableCheckin = useRef(checkin => {
        const { data: availabilityData } = store.getState().product.availablity;
        const [checkinYear, checkinMonth, checkinDay] = getDate(checkin);

        const availabilityYearsCollection = Object.keys(availabilityData)
            .sort()
            .filter(year => Number(year) >= Number(checkinYear));

        for (const year of availabilityYearsCollection) {
            const availabilityMonthsCollection = Object.keys(availabilityData[year])
                .sort()
                .filter(month => Number(month) >= Number(checkinMonth));

            for (const month of availabilityMonthsCollection) {
                const availabilityDaysCollection = Object.keys(availabilityData[year][month])
                    .sort()
                    .filter(day => Number(day) >= Number(checkinDay));

                for (const day of availabilityDaysCollection) {
                    const { a, ci, co, mi, ...rest } = availabilityData[year][month][day];

                    if ([a, ci, co, mi].every(value => value > 0)) {
                        return {
                            year,
                            month,
                            day,
                            availability: {
                                ...rest,
                                a,
                                ci,
                                mi,
                                co
                            }
                        };
                    }
                }
            }
        }
    });

    useEffect(() => {
        filtersRef.current = filters;
    }, [filters]);

    useEffect(() => {
        setFiltersRef.current = setFilters;
    }, [setFilters]);

    useEffect(() => {
        if (!isChargeLoading &&
            !isChargeOk &&
            isAvailabilityOk &&
            availabilityData &&
            filtersRef.current?.checkin) {
            const {
                year,
                month,
                day,
                availability
            } = getNextAvailableCheckin.current(filtersRef.current.checkin);

            const checkin = moment(`${year}-${month}-${day}`);

            setFiltersRef.current({
                ...filtersRef.current,
                checkin,
                checkout: checkin
                    .clone()
                    .add(availability.mi, 'days'),
            });
        }
    }, [
        isChargeLoading,
        isChargeOk,
        isAvailabilityOk,
        availabilityData
    ]);
};
