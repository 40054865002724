import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Caret = ({ className }) => (
    <svg className={clsx('carret-icon', className)} width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M1 1L7 6L13 1" stroke="#242526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

Caret.propTypes = {
    className: PropTypes.string
};

export default memo(Caret);
