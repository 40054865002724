import { createContext, memo, useCallback, useEffect, useRef, useState } from "react";

export const AvailabilityContext = createContext({});

export const AvailabilityFetchState = {
    Uninitialized: 'Uninitialized',
    InProgress: 'InProgress',
    Available: 'Available',
    Empty: 'Empty'
};

const Availability = ({ children }) => {
    const [availabilityData, setAvailabilityData] = useState([]);
    const [availabilityFetchState, setAvailabilityFetchState] = useState(AvailabilityFetchState.Uninitialized);
    const availabilityDataRef = useRef(availabilityData);

    useEffect(() => {
        availabilityDataRef.current = availabilityData;
        if (availabilityData.length > 0) {
            setAvailabilityFetchState(AvailabilityFetchState.Available);
        }
    }, [availabilityData]);

    const isAvailabilityDataExists = useCallback(() => availabilityDataRef.current.length > 0, []);
    const resetAvailabilityData = useCallback(() => {
        setAvailabilityData([]);
    }, []);

    return (
        <AvailabilityContext.Provider
            value={{
                isAvailabilityDataExists,
                availabilityData,
                resetAvailabilityData,
                setAvailabilityData,
                availabilityFetchState,
                setAvailabilityFetchState
            }}
        >
            {children}
        </AvailabilityContext.Provider>
    );
};

export default memo(Availability);
