import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import { CarouselSlider, ModalGallery } from "../Slider";
import { Modal } from '../Modal';
import { PhotosOutlinedIcon } from "components/Atoms/Icons";
import { CancelMiddleIcon } from "components/Atoms/Icons";
import { LogoIcon } from "components/Atoms/Icons";
import Picture from "@/components/Picture";
import { enhanceImageSource, getResizedImgUrl } from "@/src/utils/globalFunc";
import { MediaSrcSetEnum } from "@/static/contants";
import { SelectPicker, Form } from "components/Molecules";

const DefaultImage = 'https://www.cuddlynest.com/static/staticpages/newsroom/noimage.jpg';

export const Img = ({
    url,
    src,
    alt,
    ...props
}) => {
    const handleError = ({ target }) => {
        target.src = DefaultImage;
    };    
    return (
        <img
            loading='lazy'
            onError={handleError}
            src={url || src || DefaultImage}
            alt={alt}
            {...props}
        />
    );
}

export const MultiImageModal = ({
    enabled = true,
    enabledModal = true,
    items,
    withCover = true,
    limit = 1,
    modal = {},
    onClick = () => null
}) => {
    if(!enabled) return null;
    const { isDesktop } = useDeviceDetect();
    const [modal_enabled, setModalEnabled] = useState(false);
    const modal_info = {
        title: 'Detail Modal',
        backBtnText: 'Back to listing details',
        ...modal,
    };

    /*
    * Methods
    */
   const handleClick = useCallback(
       () => {
            onClick();
            if (enabledModal) {
                setModalEnabled(true);
            }
       }, [enabledModal, onClick]
   )

    return (
        <div
            className={clsx('n-img', {
                'n-img--has-item': items.length !== 0,
                'n-img--with-cover': (withCover && items.length !== 0)
            })}
        >
            {
                (items.length === 0)
                && (
                    <Img
                        url=""
                    /> 
                )
            }
            {
                (items.length !== 0 && limit > 1)
                && (
                    <CarouselSlider
                        items={[...items].slice(0, limit)}
                        onClick={handleClick}
                        config={{
                            dots: true,
                            variableWidth: false,
                            arrows: false
                        }}
                    />
                )
            }
            {
                (items.length !== 0 && limit === 1)
                && (
                    <Picture 
                        src={items[0].url}
                        onClick={handleClick}
                        sources={enhanceImageSource([
                            {
                                media: MediaSrcSetEnum.MOBILE,
                                srcset: getResizedImgUrl(items[0].url, '150x100')
                            },
                            {
                                media: MediaSrcSetEnum.TABLET,
                                srcset: getResizedImgUrl(items[0].url, '225x150')
                            },
                            {
                                media: MediaSrcSetEnum.DESKTOP,
                                srcset: getResizedImgUrl(items[0].url, '150x100')
                            }
                        ])}
                    />
                )
            }
            {
                (withCover && items.length !== 0)
                && (
                    <div className="n-img__cover" onClick={handleClick}>
                        <PhotosOutlinedIcon />
                    </div>
                )
            }
            {
                enabledModal
                && (
                    <Modal
                        enabled={modal_enabled}
                        onClose={() => setModalEnabled(false)}
                        fullScreen={!isDesktop}
                        closeBtn={isDesktop}
                        backBtnText={modal_info.backBtnText}
                        className={clsx('modal-multi-image', modal_info.className)}
                    >
                        <Modal.Header>
                            <LogoIcon
                                className="n-modal__logo"
                            />
                            {modal_info.title}
                            <div
                                className="n-modal__close-action"
                                onClick={() => setModalEnabled(false)}
                            >
                                <CancelMiddleIcon />
                                Close
                            </div>
                        </Modal.Header>
                        <Modal.Content>
                            <ModalGallery
                                items={items}
                            />
                        </Modal.Content>
                    </Modal>
                )
            }
        </div>
    );
}

export const GalleryListItem = ({
    images = [],
    activeImageUrl,
    setActiveImage,
    onChangeImageType,
    showCategories,
    imageTypes,
    children
}) => {
    const initialValues = useMemo(() => (
        images.reduce((vals, img, i) => ({
            ...vals,
            [`${img.id}`]: img.image_type,
        }), {})
    ), [images]);

    if (images.length > 0) {
        return (
            <Form
                className="form--main"
                initialValues={initialValues}
                onChange={onChangeImageType}
                onSubmit={(values, { setSubmitting }) => null}
                enableReinitialize
            >
            <div className="lnp-photos_gallery">
                {images.map((image, index) => (
                    <div
                        key={String(image.id ?? index)}
                        className={clsx('lnp-photos_gallery_image', { 'lnp-photos_gallery_image--active': activeImageUrl === image.url })}
                    >
                        <img
                            src={image.url}
                            onClick={() => setActiveImage(image)}
                        />
                        {showCategories
                            && <SelectPicker
                                    name={`${image.id}`}
                                    placeholder="What is this image for?"
                                    items={imageTypes}
                                />}
                    </div>
                ))}
                {children}
            </div>
            </Form>
        );
    }
    return null;
};